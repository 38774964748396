<template lang="pug">
div(v-bind='wrapperType')
  slot(:results='instance.state.value.results' :result-total='instance.state.value.resultTotal')
    template(v-if='instance.state.value.resultTotal === 0')
      slot(name='empty')

    template(v-else)
      slot(name='results' :results='instance.state.value.results' :result-count='instance.state.value.resultTotal')
        template(v-for='(result, index) in instance.state.value.results' :key='result.data?.id')
          div(
            :data-cnstrc-item-id='result.data?.id'
            :data-cnstrc-item-name='result.value'
            :data-cnstrc-item-variation-id='result.data?.variation_id'
          )
            slot(name='result' :result='result' :result-count='instance.state.value.resultTotal' :index='index')
</template>

<script setup lang="ts">
const { instance } = useConstructorInject()

const wrapperType = computed(() => {
  const attributes: Record<string, string> = {
    'data-cnstrc-num-results': instance.state.value.resultTotal.toString(),
  }

  if (instance.state.value.searchType === 'browse') {
    attributes['data-cnstrc-browse'] = ''
    attributes['data-cnstrc-filter-name'] = instance.state.value.browseFacet
    attributes['data-cnstrc-filter-value'] = instance.state.value.browseFacetValue
  } else if (instance.state.value.searchType === 'search') {
    attributes['data-cnstrc-search'] = ''
  }

  return attributes
})
</script>
