<template lang="pug">
div(v-if='data')
  CmsStoryblok(v-if='data.story?.content?.topSections' :sections='data.story?.content?.topSections')

  div(class='p-3')
    div(class='lg:container')
      Crumbs(v-if='data.crumbs' :crumbs='data.crumbs')

      div(v-if='!hideProducts' class='overflow-auto')
        FitmentInline(class='mt-4 -mx-3 lg:mt-6 lg:mx-0 bg-white lg:bg-gray-lighter')

      h1(v-if='!disableH1' id='catalog-title' class='text-center sm:text-left' data-testid='catalogTitle')
        span(v-if='fitmentStore.fitmentFormatted' class='block text-gray-dark text-xl') {{ fitmentStore.fitmentFormatted }}&nbsp;
        InlineHtml(class='block' :text='h1')

  div(v-if='!hideProducts' class='px-3 pb-6')
    div(class='lg:container')
      ConstructorWrapper(:instance='constructorInstance')
        div(class='grid grid-cols-1 lg:grid-cols-5 lg:gap-6')
          div(v-if='$device.value.isDesktop')
            CatalogConstructorCategory(class='mb-3' :category-data='data')
            ConstructorFacets

          div(class='lg:col-span-4 lg:col-start-2')
            ConstructorFacetSelections(v-if='$device.value.isDesktop' class='mb-5')

            div(class='sm:mb-6')
              CatalogConstructorToolbar(:category-data='data')

            ConstructorResults(
              class='grid grid-cols-1 gap-4 mb-2 xs:grid-cols-2 lg:grid-cols-3 lg:gap-6 lg:mb-4'
              data-testid='catalogResult'
            )
              template(#result='{ result, index }')
                ProductListing(
                  :listing='result.data'
                  :is-lazy='index >= 6'
                  @product-clicked='emitProductClicked(result, index)'
                )
                CompareCheckbox(
                  v-if='data?.story?.content?.productCompareAttributeLists?.length > 0'
                  :listing='result.data'
                )

              template(#empty)
                CatalogNoResults(class='col-span-full')

            ConstructorPagination

  CmsStoryblok(v-if='data.story?.content?.bottomSections' :sections='data.story?.content?.bottomSections')

  div(class='px-3 bg-white')
    div(class='lg:container')
      ConstructorRecommendations(pod-id='catalog-page-1')

      ConstructorRecommendations(pod-id='catalog-page-2')

  ComparePanel(v-if='data?.story?.content?.productCompareAttributeLists?.length > 0')
</template>

<script setup lang="ts">
import type { RouteLocationNormalized } from '#vue-router'

const catalogTypes = ['category', 'category-custom', 'brand', 'mmy', 'new-products', 'specials', 'search'] as const
const { $storyblok, $sitewideConfig, $uiEvents, $timing, $speedcurve } = useNuxtApp()
const router = useRouter()
const route = useRoute()
const fitmentStore = useFitmentStore()
const fitmentDisplayStore = useFitmentDisplayStore()
const urls = useUrls()
const metaTagTemplates = useMetaTagTemplates()
const templateTranslator = useTemplateTranslator()

const constructorInstance = useConstructor('catalog')

const type = getType(route) || ''
const slug = getSlug(type)

const { data, error } = await useAsyncData('constructor-catalog-page', async () => {
  if (!type || type === 'search') {
    const data = formatCatalogData({})
    await updateSearch(data)
    return data
  }

  let endpoint: ApiUrlKey

  if (type === 'specials' || type === 'new-products') endpoint = 'catalog'
  else if (type === 'category-custom') endpoint = 'category'
  else endpoint = type

  let apiUrl = urls.getApiUrl(endpoint) + slug

  const options = new URLSearchParams()

  // If we are in preview mode, we don't want to hit the cat endpoint with a customFacet because if the page is not published, it will 404.
  if (route.params.facetSlug && !$storyblok.isPreview.value)
    options.append('customFacet', route.params.facetSlug.toString())
  if (route.query.timeTravel) options.append('timeTravel', route.query.timeTravel.toString())

  // If we added any query strings, add them to the URL
  if (options.size > 0) apiUrl = apiUrl + '?' + options.toString()

  const timingApiKey = `${type}-api`
  $timing.start(timingApiKey)
  const catalogData = formatCatalogData(await $fetch(apiUrl))
  $timing.end(timingApiKey)

  // Gets the initial preview data (saved not published)
  const preview: CatalogStory = (await $storyblok.getPreviewStory()) as CatalogStory
  if (preview) catalogData.story = preview.story

  const timingConstructorKey = `${type}-constructor`
  $timing.start(timingConstructorKey)
  await updateSearch(catalogData)
  $timing.end(timingConstructorKey)

  // create empty selects and reposition optionals
  fitmentDisplayStore.setupFitment(catalogData.requiredFitmentOptions)

  return catalogData
})

if (error.value) {
  if (error.value?.statusCode === 404) throw createError({ statusCode: 404, fatal: true })
  throw createError({ statusCode: 500, message: error.value.message, fatal: true })
}

onBeforeMount(() => {
  $speedcurve.track('Catalog Page')
})

onMounted(() => {
  // This function will check if the fitment modal needs to open because of the sameDayFacet
  tryFitmentModalOpen()

  // refill selects (this would run twice on first hit since app.vue initSelects too - meh it's cached)
  fitmentDisplayStore.initSelects()

  emitProductList()

  // Updates the data as user edits in storyblok
  $storyblok.addListener((story) => {
    if ($storyblok.isCatalogStory(story) || $storyblok.isBrandStory(story)) data.value.story = story.story
  })
})

// if they are going to a non-catalog (or the search page) page, re-setup the fitment selects to their defaults and refill
const unRegisterRouteGuard = router.afterEach((to) => {
  const toType = getType(to)
  if (!toType || toType === 'search') {
    fitmentDisplayStore.setupFitment()
    fitmentDisplayStore.initSelects()
  }
})

onUnmounted(() => {
  unRegisterRouteGuard()
})

watch(
  () => route.query,
  async (toQuery, fromQuery) => {
    await updateSearch(data.value)
    // TODOLATER: Nuxt 3 - Josh - This seems weird, I don't get the use case here.
    // ----
    // This function will check if the fitment modal needs to open because of the sameDayFacet
    // We don't want to get in a forceOpen loop so we will only try to open the fitment modal
    // when we don't have sameDayShipping in the url and we didn't come from a url will a mod open
    if (!toQuery.sameDayShipping && fromQuery.mod) tryFitmentModalOpen()

    emitProductList()
  }
)

const disableH1 = computed(() => {
  return data.value?.story?.content?.disableH1 === 'true'
})

const h1 = computed(() => {
  return data.value?.story?.content?.h1 || data.value?.h1 || catalogName.value
})

const catalogName = computed(() => {
  return route.name?.toString().includes('category-custom') && $storyblok.isPreview.value
    ? `${data.value.story?.content?.facetValue || ''} ${data.value.title}`
    : data.value.title
})

const hideProducts = computed(() => {
  return data.value.story?.content?.hideProducts === 'true'
})

const isNoIndex = route.name?.toString() === 'search' || data.value.story?.content.disableSeoIndex === 'true'

const socialImageUrl = (() => {
  if (data.value.story?.content.shareImage?.filename)
    return $storyblok.getNormalizedAsset(data.value.story.content.shareImage.filename, 1920)?.src

  const firstResult = constructorInstance.state.value.results[0]

  if (firstResult && firstResult.data) {
    return urls.getImageUrl(firstResult.data.image.key, firstResult.data.image.filename, 800, 600, 80, 'fff')
  }
})()

const page = computed(() => constructorInstance.state.value.page)

const metaTemplates = getMetaTemplates()
const metaVariables = {
  ...(['category-custom', 'category'].includes(type) && { CATEGORY: catalogName.value }),
  ...(type === 'brand' && { BRAND: data.value.name }),
}

useMetaTags({
  title: computed(
    () =>
      (page.value > 1 ? `Page ${page.value} - ` : '') +
      templateTranslator.translateTemplate(metaTemplates.title, metaVariables)
  ),
  description: templateTranslator.translateTemplate(metaTemplates.description, metaVariables),
  isNoIndex,
  ...(socialImageUrl && { socialImageUrl }),
})

function emitProductList() {
  const { activeFacets, results } = constructorInstance.state.value

  if (results.length === 0) return

  if (activeFacets.length > 0) {
    $uiEvents.$emit('constructorProductListFiltered', {
      activeFacets,
      results,
    })
  } else {
    $uiEvents.$emit('constructorProductListViewed', {
      results,
    })
  }
}

function emitProductClicked(product: any, position: number) {
  $uiEvents.$emit('constructorProductClicked', {
    product,
    position: position + 1,
  })
}

function tryFitmentModalOpen() {
  if (
    !$sitewideConfig.config.sameDayShippingEnabled ||
    !route.query.sameDayShipping ||
    fitmentDisplayStore.hasFullFitment
  )
    return

  fitmentDisplayStore.showFitmentModal({ isSameDayShippingMode: true })
}

// gets the site configured metas and overrides with storyblok as needed
function getMetaTemplates() {
  const storyblokMetaTemplates = {
    ...(data.value.story?.content.metaTitle && {
      title: data.value.story.content.metaTitle,
    }),
    ...(data.value.story?.content.metaTitleFitment && {
      titleFitment: data.value.story.content.metaTitleFitment,
    }),
    ...(data.value.story?.content.metaDescription && {
      description: data.value.story.content.metaDescription,
    }),
    ...(data.value.story?.content.metaDescriptionFitment && {
      descriptionFitment: data.value.story.content.metaDescriptionFitment,
    }),
    ...(data.value.story?.content.metaDescriptionMake && {
      descriptionMake: data.value.story.content.metaDescriptionMake,
    }),
    ...(data.value.story?.content.metaDescriptionModel && {
      descriptionModel: data.value.story.content.metaDescriptionModel,
    }),
    ...(data.value.story?.content.metaDescriptionYear && {
      descriptionYear: data.value.story.content.metaDescriptionYear,
    }),
  }

  return metaTagTemplates.resolveTemplates(type, storyblokMetaTemplates)
}

async function updateSearch(catData: any) {
  const filterExpression: Parameters<typeof constructorInstance.setPreFilterExpression>[0] = {
    and: [{ name: 'isAccessory', value: 'False' }],
  }

  const browseSlug = slug ?? ''

  constructorInstance.setSearchType('browse')

  // If we are a non-transactional site, then we only want to show RT products
  if ($sitewideConfig.config.nonTransactionalEnabled) {
    const brands = $sitewideConfig.config.madeByRealTruckBrands.data ?? []
    if (brands.length > 0) filterExpression.and.push({ or: brands.map((brand) => ({ name: 'brand', value: brand })) })
  }

  switch (type) {
    case 'category':
    case 'category-custom':
      constructorInstance.setBrowseFacet('group_id', browseSlug.replaceAll('/', '-'))
      constructorInstance.setGroupDepth(1)

      if (type === 'category-custom' && catData.story) {
        Object.entries(catData.story.content)
          .filter(([key, value]) => key.startsWith('facetName') && value)
          .forEach(([facetKey, facetKeyValue]) => {
            const facetNumber = facetKey.replace('facetName', '')
            const facetValue = catData.story.content[`facetValue${facetNumber}`]

            const values = facetValue
              // Split on comma and trim whitespace
              .split(',')
              .map((v) => v.trim())
              // Remove empty values
              .filter((v) => v)

            // Add each value to the filter expression
            if (values.length > 0)
              filterExpression.and.push({ or: values.map((value) => ({ name: facetKeyValue, value })) })
          })
      }

      break

    case 'brand':
      constructorInstance.setBrowseFacet('brandSlug', browseSlug)
      if ($sitewideConfig.config.useSecondLevel) constructorInstance.setGroupDepth(2)

      // We don't show replacement parts on brand pages
      filterExpression.and.push({ name: 'isReplacementPart', value: 'False' })

      break

    case 'specials':
      constructorInstance.setBrowseFacet('hasSpecial', 'True')
      if ($sitewideConfig.config.useSecondLevel) constructorInstance.setGroupDepth(2)

      break

    case 'new-products':
      constructorInstance.setBrowseFacet('isNew', 'True')
      if ($sitewideConfig.config.useSecondLevel) constructorInstance.setGroupDepth(2)

      break

    case 'mmy':
      constructorInstance.setBrowseFacet('group_id', 'all')
      if ($sitewideConfig.config.useSecondLevel) constructorInstance.setGroupDepth(2)

      break

    case 'search':
      constructorInstance.setSearchType('search')
      if ($sitewideConfig.config.useSecondLevel) constructorInstance.setGroupDepth(2)

      break
  }

  // Set filters based upon current fitment
  if (fitmentStore.makeSlug) {
    const fitmentString = [
      fitmentStore.makeSlug,
      fitmentStore.modelSlug,
      fitmentStore.year,
      fitmentStore.bedSlug,
      fitmentStore.bodySlug,
      fitmentStore.engineSlug,
    ]
      .map((slug) => slug || 'none')
      .join('*')

    filterExpression.and.push({
      or: [
        { name: 'make*model*year*bed*body*engine', value: fitmentString },
        { name: 'make*model*year*bed*body*engine', value: `none*none*none*none*none*none` },
      ],
    })
  }

  constructorInstance.setPreFilterExpression(filterExpression)

  // Sync the state from the query of the current route
  constructorInstance.setSearchParamsFromQuery(route.query)

  // trigger the actual search to update the data.
  await constructorInstance.search()
}

function getType(route: RouteLocationNormalized): (typeof catalogTypes)[number] | undefined {
  const baseRouteName: any = route.matched[0].name?.toString().replace('-mmy', '') || ''
  if (catalogTypes.includes(baseRouteName)) return baseRouteName
}

function getSlug(type?: string) {
  switch (type) {
    case 'category-custom':
    case 'category': {
      const arr = []
      for (let i = 0; i <= (getLastCategoryLevel(route) || 0); i++) arr.push(route.params[`lvl${i}`])

      return arr.join('/')
    }
    case 'brand':
      return route.params.brandSlug as string

    case 'mmy': {
      const mmy = [route.params.makeSlug]
      if (route.params.modelSlug) mmy.push(route.params.modelSlug)
      if (route.params.year) mmy.push(route.params.year)
      return mmy.join('/')
    }
    case 'new-products':
    case 'specials':
      // We can just return the type as the slug for specials and new-products
      return type

    default:
      // If its not one of these then we need to return nothing
      return null
  }
}

function formatCatalogData(catalogData: any) {
  const data = {
    ...catalogData,
    type,
    title: catalogData.name,
  }

  switch (type) {
    case 'mmy':
      data.title = 'Parts &amp; Accessories'
      break

    case 'specials':
      data.title = 'Deals'

      break

    case 'new-products':
      data.title = 'New Products'

      break

    case 'search': {
      const query = route.query.q?.toString().replace(/[^\da-z-+ ]+/gi, '')

      data.title = `Search results for "${query || ''}"`

      break
    }
  }

  return data
}
</script>
